.ArticleList {
    @mixin studyPageSectionPadding;
}

.Header {
    margin-bottom: 64px;

    @media (--tablet) {
        @mixin grid;
        gap: 24px;
        grid-template-columns: repeat(12, 1fr);

        & .Heading {
            grid-row: 1;
            grid-column: 1 / span 12;
        }

        & .Teaser {
            grid-row: 2;
            grid-column: 3 / span 8;
        }
    }

    @media (--desktop) {
        margin-bottom: 96px;
    }
}

.Heading {
    @mixin sectionHeading;
}

.Teaser {
    @mixin sectionTeaser;
    @mixin hyphenateWords manual;
    margin: 24px 0 0;

    @media (--tablet) {
        margin: 0;
    }
}

.List {
    @mixin clearList;

    @media (--tablet) {
        display: flex;
        flex-wrap: wrap;
        row-gap: 32px;
    }
}

.ListItem:not(:last-child) {
    margin-bottom: 64px;

    @media (--tablet) {
        margin-bottom: 0;
    }
}

.Variant--dark {
    background: var(--blackLighten10Color);
    color: var(--whiteBaseColor);
}

.ListVariant--oneColumn {
    @media (--tablet) {
        & .ListItem {
            lost-column: 12/12 1;
        }
    }
}

.ListVariant--twoColumns {
    @media (--tablet) {
        & .ListItem:nth-child(2n + 1) {
            lost-column: 8/12 2;
        }

        & .ListItem:nth-child(2n) {
            lost-column: 4/12 2;
        }
    }
}

.ListVariant--threeColumns {
    @media (--tablet) {
        & .ListItem:nth-child(3n + 1) {
            lost-column: 5/12 3;
        }

        & .ListItem:nth-child(3n + 2) {
            lost-column: 4/12 3;
        }

        & .ListItem:nth-child(3n) {
            lost-column: 3/12 3;
        }
    }
}
