.ArticleCard {
    cursor: pointer;

    &:hover {
        & .Picture {
            opacity: 0.8;
        }
    }
}

.Picture {
    @mixin imageHoverAnimation;
    margin-bottom: 16px;

    &.Picture--16x9 {
        @mixin aspectRatio 9/16;
    }

    &.Picture--3x4 {
        @mixin aspectRatio 4/3;
    }

    &.Picture--2x3 {
        @mixin aspectRatio 3/2;
    }
}

.Heading {
    @mixin h40;
    @mixin hyphenateWords;
    margin: 0 0 16px;

    @media (--tablet) {
        @mixin h20;
    }
}

.Description {
    @mixin p40;
    @mixin hyphenateWords;
    margin-bottom: 16px;

    @media (--tablet) {
        @mixin p20;
    }
}

.LetterIcon {
    @mixin fixedRatioSvgIcon 32px;

    @media (--tablet) {
        @mixin fixedRatioSvgIcon 48px;
    }
}

.Variant--fullInfo {
    & .Info.Info {
        width: 100%;
        max-width: 100%;
    }
}

.Variant--smallTypography {
    & .Heading {
        @mixin h50;
    }

    & .Description {
        @mixin p40;
    }
}

.Variant--vertical {
    @media (--tablet) {
        & .Info {
            lost-column: 4/5 0 0;
        }
    }
}

.Variant--fullWidth {
    @media (--tablet) {
        & .Info {
            display: flex;
            gap: 32px;
            justify-content: space-between;

            & .Heading,
            & .AdditionalInfo {
                flex: 1;
            }
        }
    }
}

.Variant--horizontal {
    @media (--tablet) {
        display: flex;

        & .PictureContainer {
            lost-column: 2/3 2;
        }

        & .Picture {
            margin-bottom: 0;
        }
    }
}
